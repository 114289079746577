import BaseBlockContent from "@sanity/block-content-to-react";
import React, { FC } from "react";
import clientConfig from "../../../client-config";
import serializers from "../serializers";

import style from "./block-content.module.scss";
interface IBlockContent {
  blocks: any;
  overwriteStyles?: any;
}
const BlockContent: FC<IBlockContent> = ({ blocks, overwriteStyles }: IBlockContent) => (
  <BaseBlockContent
    className={`${style.blockContent} ${overwriteStyles}`}
    blocks={blocks}
    serializers={serializers}
    {...clientConfig.sanity}
  />
);

export default BlockContent;
